<template>
  <div class="wameed-dashboard-page-content mb-5">
    <page-header
      :title="$t('nav.projects')"
      :sub-title="$t('common.projects_list_subtitle')"
    />
    <section class="wameed-dashboard-page-content_body">
      <b-row class="no-gutters px-2">
        <b-col lg="3" md="4" class="px-0">
          <b-card
            no-body
            class="
              cursor-pointer
              wameed-card
              align-items-center
              justify-content-center
              py-5
            "
            @click="projectClosingRequestsDetails"
          >
            <b-avatar variant="light-main" class="my-4" rounded size="60">
              <project-closing-requests-icon />
            </b-avatar>
            <h4 class="h-panel__title text-reg-20 my-4">
              {{ $t('project_page.project_closing_requests') }}
            </h4>
          </b-card>
        </b-col>
        <b-col lg="3" md="4" class="px-0">
          <b-card
            no-body
            class="
              cursor-pointer
              wameed-card
              align-items-center
              justify-content-center
              py-5
            "
            @click="projetCancelRequestsDetails"
          >
            <b-avatar variant="light-main" class="my-4" rounded size="60">
              <projec-cancel-requests-icon />
            </b-avatar>
            <h4 class="h-panel__title text-reg-20 my-4">
              {{ $t('project_page.projec_cancel_requests') }}
            </h4>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    PageHeader,
  },
  data() {
    return {
      // permissions: loginData.permissions,
    };
  },
  computed: {
    ...mapGetters({
      getProfileInfo: 'getProfileInfo',
    }),
  },
  mounted() {
    this.IsCanPermission();
  },
  methods: {
    IsCanPermission(permission) {
      const loginData = this.getProfileInfo;
      if (loginData) {
        return loginData.permissions.includes(permission);
      }
    },
    projectClosingRequestsDetails() {
      this.$router.push({
        name: 'project-closing-requests',
        params: { lang: this.$i18n.locale },
      });
    },
    projetCancelRequestsDetails() {
      this.$router.push({
        name: 'project-cancel-requests',
        params: { lang: this.$i18n.locale },
      });
    },
  },
};
</script>
